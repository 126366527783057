<template>
	<div v-show="isLogin">
		<!-- App bar -->
		<v-app-bar
			app
			height="60"
			flat
			color="secondary"
			class="elevation-1 pa-0"
		>
			<template v-if="!isOpen">
				<v-app-bar-nav-icon color="white" @click="isOpen=!isOpen"></v-app-bar-nav-icon>
			</template>
			<v-spacer></v-spacer>
			<div v-if="$vuetify.breakpoint.mdAndUp" class="fill-height py-2">
				<img src="/images/logo.svg" height="100%">
			</div>
			<v-spacer></v-spacer>
			<!-- <v-btn
				v-if="cartBtn && !isGuest"
				color="primary"
				class="mr-n3"
				style="height: 100%;"
				@click="toUseProfile"
			>
				<v-icon color="secondary" medium>mdi-cart-outline</v-icon>
			</v-btn> -->
		</v-app-bar>

		<!-- Side bar -->
		<v-navigation-drawer
			v-model="isOpen"
			app
			temporary
			floating
			color="grey"
			class="elevation-1"
		>   
			<v-row 
				dense
				class="ma-0"
				align="center"
				:class="$vuetify.breakpoint.mdAndUp ? '' : 'secondary' "
				style="height: 60px;"
			>
				<v-col class="fill-height pa-2">
					<img v-if="$vuetify.breakpoint.mdAndUp" src="/images/logo-solo.png" height="100%">
					<img v-else src="/images/logo.svg" height="100%">
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="auto">
					<v-app-bar-nav-icon
						:color="$vuetify.breakpoint.mdAndUp ? '' : 'white' "
						@click="isOpen=!isOpen"
					></v-app-bar-nav-icon>
				</v-col>
			</v-row>
			<v-list
				dense flat
				class="py-0 px-2"
			>
				<div class="px-4 pt-8 pb-2">
					<div v-if="$auth.user()" class="body-2">
						<span>{{ $auth.user().name || '' }}</span>
					</div>
					<v-divider class="mt-2"></v-divider>
				</div>
				<v-list-item-group
					:key="listKey"
					v-model="selected"
				>
					<template v-for="item in route_groups[0].routes">
						<v-list-item
							:key="item.title"
							:to="item.route"
							color="secondary"
						>
							<template v-slot:default="{ active }">
								<v-list-item-title>
									<v-icon
										v-if="active"
										color="secondary"
										size="16"
										class="mr-1"
									>
										mdi-hexagon-slice-6
									</v-icon>
									<v-icon v-else class="px-1"></v-icon>
									<span>{{ item.title }}</span>
								</v-list-item-title>
							</template>
						</v-list-item>
					</template>
				</v-list-item-group>
			</v-list>
			<template #append>
				<div 
					class="text-center"
					style="width: 100%"
				>
					<div class="pa-4 pb-2">
						<v-btn
							outlined block
							color="error"
							class="text-capitalize"
							@click="logout()"
						>
							<span v-if="$authUser.isGuest()">Sign In/Sign Up</span>
							<span v-else>Log Out</span>
						</v-btn>
					</div>
					<div class="x-caption text-center px-2 py-1 mt-3 black">
						<a 
							class="text-decoration-none opposite--text" 
							href="https://advisoryapps.com" 
							target="_blank" 
							rel="noopener noreferrer"
						>
							<span class="white--text">Powered by <strong>AdvisoryApps</strong></span>
						</a>
					</div>
				</div>
			</template>
		</v-navigation-drawer>
	</div>
</template>

<script>
import * as NAVBAR from '@/configs/navbar'
import { mapGetters, mapActions } from "vuex"

export default {
	components:{},
	data () {
		return {
			title : process.env.MIX_APP_NAME ?? 'title',
			selected: null,
			isOpen: false,
			menu: false,
			route_groups: [],
			cartBtn : false,
			listKey: 0,
		}
	},
	computed: {
		isLogin()
		{
			return this.$auth.check()
		}
	},
	watch:{
		$route (to,from){
			this.computedSelected()
		},
		isOpen()
		{
			this.listKey+=1
		}
	},
	created(){
		this.getNavRoute()
		this.computedSelected()
	},
	methods: {
		...mapActions(["resetOrderCustomizations", "resetSelectedSeat"]),
		getNavRoute(){
			let navi_key = 'user'
			// if(this.$auth.user().role_id == 4) {
			// 	navi_key = 'role_2'
			// }
			this.route_groups = NAVBAR.routes[navi_key]
		},
		computedSelected(){
			let currentPath = this.$route.path;
			let matchPath = this.route_groups[0].routes.findIndex((item)=>{
				return currentPath.startsWith(item.route)
			})
			console.log(matchPath,"matchPath")
			this.selected = matchPath
		},
		toggleDarkMode() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			localStorage.setItem("dark-theme", this.$vuetify.theme.dark);
		},
		logout(){
			window.postMessage("logging_out")
			window.parent.postMessage("logging_out")
			this.resetOrderCustomizations()
			this.resetSelectedSeat()
			this.$auth.logout({
				makeRequest: true,
				data: {},
				redirect: '/login',
			});
		},
	}
}
</script>

<style scoped>
.x-caption{
    font-size: 0.6rem !important;
    font-weight: 400;
    letter-spacing: 0.01em !important;
    line-height: 1rem;
    font-family: "Roboto", sans-serif !important;
}
</style>