const MODULE_HOME = "home"
const MODULE_ORDER_HISTORY = "order-history"

const routes = {
	'user' : [
		{
			title : null,
			routes : [
				{
					title : "New Order",
					route: "/new-order",
					sub: null 
				},
				{
					title : "Order History",
					route: '/order-histories',
					sub: null 
				},
				{
					title : "Promotions",
					route: '/promotions',
					sub: null 
				},
				{
					title : "Contact Us",
					route: '/contact-us',
					sub: null 
				},
				{
					title : "Report a Problem",
					route: '/report',
					sub: null 
				},
				{
					title : "Policies",
					route: '/policy',
					sub: null 
				},
				// {
				// 	title : "Test",
				// 	route: '/test',
				// 	sub: null 
				// },
			]
		},
	],
};

export {
	routes,
	MODULE_HOME
}