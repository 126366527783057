<template>
	<div class="fill-height">
		<Navbar></Navbar>
		<v-container :key="keepAliveKey" v-bind="attrs">
			<transition name="slide-fade">
				<keep-alive max="6">
					<router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
				</keep-alive>
			</transition>
			<transition name="slide-fade">
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</transition>
			<!-- <transition name="slide-fade">
				<keep-alive max="10">
					<router-view :key="$route.fullPath"></router-view>
				</keep-alive>
			</transition> -->
		</v-container>
	</div>
</template>

<script>
import Navbar from '@/components/Navbar'
export default {
	components: { Navbar },
	data(){
		return {
			attrs: {},
			keepAliveKey: 1,
		}
	},
	watch:{
		'$route':{
			handler(newRoute){
				let { attrs, keepAlive } = newRoute.meta
				if(!keepAlive)
				{
					this.keepAliveKey = this.keepAliveKey + 1
				}
				// merge & overwrite
				this.attrs = Object.assign(this.getDefaultAttributes(), attrs)
				
			},
			immediate : true,
		},
	},
	created(){
	},
	methods:{
		getDefaultAttributes(){
			return {
				fluid: true,
				'fill-height': true,
				'class': 'align-start',
			}
		},
		scrollToTop() {
			window.scrollTo(0,0);
		}
	}
}
</script>